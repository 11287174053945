import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereSE.jpg"
import SiImg from "../../img/si.jpg"

function Si() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap siWrap">
        <p className="fobMainImg siMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap siOverview">
          <div className="textWrap">
            <h2>통합방재시스템 SI</h2>
            <p className="siImg">
              <img src={SiImg} alt="" />
            </p>
            <p className="bodyText">
              <b>운영 시나리오에 따른 통합연동제어</b> <br />
              -출입통제 이벤트 <br />
              -전력 부하경보
              <br />
              -비상화재
              <br />
              <br />
              <b>연동항목</b> <br />
              -설비관제 <br />
              -전력/조명 <br />
              -방재 <br />
              -출입통제 <br />
              -CCTV <br />
              -빌딩안내
              <br />
              -전관방송 <br />
              -엘리베이터 관제 <br />
              -BEMS <br />
              -FMS
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Si
